import { SayCheese, AttachDocs } from "docu-booth-package";
import { Tiles } from "components/layouts/Tiles";
import { Text } from "components/Text";
import { Button } from "components/Button";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { InfoEmptyIcon } from "components/icons/InfoEmptyIcon";

const ModalContent = ({
  files: templateFiles,
  onSubmit,
  children,
  error,
  typeOfFile,
  partnerPersonalData,
  valuesPreviousOwner,
}) => {
  const [modalMode, setModalMode] = useState(null);

  const [files, setFiles] = useState(() => {
    if (typeOfFile !== "factura") {
      return templateFiles.map((f) => ({ ...f }));
    } else {
      return templateFiles
        .filter((f) => f.label === "Documento 1" || f.label === "Document 1")
        .map((f) => ({ ...f }));
    }
  });

  const [fileUpload, setImageUpload] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const isImageUpload = files.some((image) => image.src !== null);
    setImageUpload(isImageUpload);
  }, [files]);

  const documentInstructions =
    typeOfFile === "dniactual"
      ? partnerPersonalData.vat === undefined
        ? t("funnel.signup.thanks.undefined_data")
        : `${partnerPersonalData.vat} · ${partnerPersonalData.name} ${partnerPersonalData.surname}`
      : typeOfFile === "dnianterior"
      ? valuesPreviousOwner.previous_owner_vat === undefined ? t("funnel.signup.thanks.undefined_data") :`${valuesPreviousOwner.previous_owner_vat} · ${valuesPreviousOwner.previous_owner_name} ${valuesPreviousOwner.previous_owner_surname}`
      : t("funnel.signup.thanks.modal_instructions");

  return (
    <Box>
      <Box style={{ paddingBottom: 20 }}>{children}</Box>
      {modalMode === null && (
        <Box>
          <Box>
            <Box>
              <Text bold size="lg">
                {t("funnel.signup.thanks.modal_upload_question." + typeOfFile)}
              </Text>
            </Box>
            <Text size="md">{documentInstructions}</Text>
          </Box>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 20,
              marginTop: 20,
              justifyContent: "center",
            }}
          >
            <Button fullWidth={false} onClick={() => setModalMode("files")}>
              {t("funnel.signup.thanks.modal_attach_button_option")}
            </Button>
            {typeOfFile !== "factura" && (
              <Button fullWidth={false} onClick={() => setModalMode("photo")}>
                {t("funnel.signup.thanks.modal_photo_button_option")}
              </Button>
            )}
          </Box>
        </Box>
      )}
      {error && (
        <Box width="100%">
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      {modalMode === "photo" && (
        <>
          <Text bold size="lg">
            {t("funnel.signup.thanks.modal_docubooth_info." + typeOfFile)}
          </Text>
          <Box style={{ marginBottom: 20 }}>
            <Text size="md">{documentInstructions}</Text>
          </Box>
          <SayCheese files={files} setFiles={(files) => setFiles(files)} />
          <Button onClick={() => onSubmit(files)} disabled={!fileUpload}>
            Submit
          </Button>
          <Box
            style={{
              display: "flex",
              aligItems: "center",
              gap: 10,
              marginTop: 20,
            }}
          >
            <InfoEmptyIcon />
            <Text>{t("funnel.signup.thanks.info_message")}</Text>
          </Box>
        </>
      )}
      {modalMode === "files" && (
        <>
          <Text bold size="lg">
            {t("funnel.signup.thanks.modal." + typeOfFile)}
          </Text>
          <Box style={{ marginBottom: 20 }}>
            <Text size="md">{documentInstructions}</Text>
          </Box>
          <AttachDocs
            files={files}
            setFiles={(files) => setFiles(files)}
            accept={["image/*", "application/pdf"]}
          />
          <Button onClick={() => onSubmit(files)} disabled={!fileUpload}>
          {t("docubooth.stepper.submit")}
          </Button>
          <Box
            style={{
              display: "flex",
              aligItems: "center",
              gap: 10,
              marginTop: 20,
            }}
          >
            <InfoEmptyIcon />
            <Text>{t("funnel.signup.thanks.info_message")}</Text>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ModalContent;
