import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import { DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import { Backdrop } from "components/Backdrop";
import { noop } from "lib/fn/noop";
import { CloseIcon } from "components/icons/CloseIcon";

const inIframe = (() => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
})();

const useStyles = makeStyles((theme) => ({
  paperScrollPaper: {
    maxHeight: `calc(100% - ${theme.spacing(3)})`,
  },
  paperFullWidth: {
    maxWidth: `calc(100% - ${theme.spacing(3)})`,
  },
  paper: {
    borderRadius: 15,
  },
}));

const boxStyles = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflow: "auto",
  px: [3, 8],
  py: [3, 5],
  paddingBottom: 8,
};

export const Modal = ({
  isOpen,
  onClose,
  children,
  showCloseButton = true,
  showBackdrop = false,
  disableBackdropClick = false,
  fullWidth = true,
}) => {
  const [scrollCorrection, setScrollCorrection] = useState(0);
  const styles = useStyles();

  useEffect(() => {
    if (!isOpen || !window.parentIFrame) {
      return;
    }

    window.parentIFrame.getPageInfo((obj) => {
      // obj.scrollTop : The number of pixels between the top edge of the iframe and the top edge of the iframe viewport
      // obj.clientHeight : The height of the containing document, considering the viewport, in pixels
      // obj.iframeHeight : The height of the iframe in pixels
      setScrollCorrection(
        obj.scrollTop + obj.clientHeight / 2 - obj.iframeHeight / 2
      );
    });
  }, [isOpen]);

  return (
    <Dialog
      classes={styles}
      style={{ transition: "top 100ms", top: scrollCorrection }}
      open={isOpen}
      onExited={onClose}
      onBackdropClick={!disableBackdropClick ? onClose : noop}
      fullWidth={fullWidth}
    >
      <DialogTitle disableTypography>
        {showCloseButton && (
          <Box position="absolute" top={0} right={0} padding={2}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <Box {...boxStyles}>{children}</Box>
      <Box paddingBottom={4} />
      <Backdrop open={showBackdrop} />
    </Dialog>
  );
};
