import { get, patch } from "axios";

export async function getRequiredDocs(crmLeadToken) {
  const res = await get(`/required-documentation/${crmLeadToken}/`);
  return res.data;
}

export async function patchRequiredDocs(crmLeadId, data) {
  try {
    const res = await patch(`/required-documentation/${crmLeadId}/`, data);
    return res.data;
  } catch (error) {
    console.error(
      "Error in patchRequiredDocs:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
}
