import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Button } from "components/Button";
import { useTranslation } from "react-i18next";
import { CheckGreenIcon } from "components/icons/CheckGreenIcon";
import { theme } from "../../theme";
import { Text } from "components/Text";

const greenColor = theme.palette.semantic.main;
const greyColor = theme.palette.background.main;

const DocumentButton = ({
  category,
  onClick,
  submitted,
  partnerPersonalData,
  valuesPreviousOwner,
}) => {
  const labels = {
    dniactual: "Titular Actual",
    factura: "Factura del teu operador actual",
    dnianterior: "Titular Nou",
  };
  const { t } = useTranslation();

  const personalDataLabels = {
    dniactual:
      partnerPersonalData.vat === undefined
        ? t("funnel.signup.thanks.undefined_data")
        : `${partnerPersonalData.vat} · ${partnerPersonalData.name} ${partnerPersonalData.surname}`,
    dnianterior:
      valuesPreviousOwner.previous_owner_vat === undefined
        ? t("funnel.signup.thanks.undefined_data")
        : `${valuesPreviousOwner.previous_owner_vat} · ${valuesPreviousOwner.previous_owner_name} ${valuesPreviousOwner.previous_owner_surname}`,
    factura: t("funnel.signup.thanks.modal_instructions"),
  };

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid ${greyColor}`,
        paddingBottom: 20,
      }}
    >
      <Box style={{ width: "60%" }}>
        <Box>
          <Text bold size="lg">
            {labels[category]}
          </Text>
        </Box>
        <Box>
          <Text size="md">{personalDataLabels[category]}</Text>
        </Box>
      </Box>
      <Box style={{ width: "40%" }}>
        {submitted ? (
          <Box
            style={{
              display: "flex",
              gap: 20,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography style={{ color: greenColor }}>
              {t("funnel.signup.thanks.documentation_message_sent")}
            </Typography>
            <CheckGreenIcon />
          </Box>
        ) : (
          <Button onClick={() => onClick(category)}>
            {category === "factura"
              ? t("funnel.signup.thanks.invoice_send_button_text")
              : category === "dniactual"
              ? t("funnel.signup.thanks.actual_send_button_text")
              : category === "dnianterior"
              ? t("funnel.signup.thanks.previous_send_button_text")
              : null}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DocumentButton;
