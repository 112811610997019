import { TopBarLayout } from "components/layouts/TopBarLayout";
import { styled, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import { Tiles } from "components/layouts/Tiles";
import { Stepper } from "components/Stepper";
import { Spinner } from "components/Spinner";
import { SessionListener } from "components/shared/SessionListener";
import { Data } from "./Data";
import { Payment } from "./Payment";
import { Confirm } from "./Confirm";
import { Thanks } from "./Thanks";
import { useTranslation } from "react-i18next";
import { Container } from "components/layouts/Container";
import { Text } from "components/Text";
import { PaymentSummary } from "./PaymentSummary";
import { useCatalog } from "hooks/queries/useTariffs";
import { useApplicationContext } from "hooks/useApplicationContext";
import { reusePartnerAddresses } from "lib/domain/somconnexio/reuseAddresses";
import { buildAnalyticsParamFromState } from "./shared/buildAnalyticsParamFromState";
import { useStore } from "hooks/useStore";
import { compact, uniq, uniqueId } from "lodash";
import { useQueryParam, StringParam, JsonParam } from "use-query-params";
import { getFiberContracts } from "lib/api/fiberContracts";
import { useLinesFormDerivedState } from "hooks/useLinesFormDerivedState";
import { useContracts } from "hooks/queries/useContracts";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: 0, 
    },
  },
  hasSidebar: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "300px",
    },
  },
}));

export const SignUp = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [intent] = useQueryParam("intent", JsonParam);
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);
  const state = useStore((state) => state);
  const setFiberLinesToAssociateMobile = useStore(
    (state) => state.setFiberLinesToAssociateMobile
  );
  const { internetLineInFormData } = useLinesFormDerivedState();

  const steps = ["/signup/data", "/signup/payment", "/signup/confirm"];
  const shouldShowStepper = steps.includes(pathname);
  const shouldShowPaymentSummary = shouldShowStepper;

  const classes = useStyles();

  useEffect(() => {
    async function fetchFiberLinesToAssociateMobile() {
      try {
        setFiberLinesToAssociateMobile(await getFiberContracts());
      } catch (err) {
        return;
      }
    }
    if (loggedIn && !Boolean(internetLineInFormData)) {
      fetchFiberLinesToAssociateMobile();
    }
  }, [loggedIn, internetLineInFormData]);

  const { isLoading: isCatalogLoading, data: catalog } = useCatalog();
  const [tariffs, setTariffs] = useState([]);
  useEffect(() => {
    if (isCatalogLoading) return;

    if (intent.isSharedLines) {
      const belongsToPack = intent.lines.reduce((packCode, line) => {
        return packCode || line.belongs;
      }, void 0);
      const pack = catalog.packs.find((pack) => pack.code === belongsToPack);
      const packProductCodes = pack.products.map((p) => p.code);
      const filteredTariffs = tariffs.filter(
        (tariff) => packProductCodes.indexOf(tariff.code) === -1
      );
      setTariffs(filteredTariffs.concat(pack.products));
    } else {
      setTariffs(catalog.tariffs);
    }
  }, [isCatalogLoading]);

  const { isLoading: isSubscriptionsLoading, data: subscriptions } =
    useContracts({
      userName: currentUser?.username,
      enabled: loggedIn,
    });
  const saveAddress = useStore((state) => state.saveAddress);
  const setAvailablePaymentMethods = useStore(
    (state) => state.setAvailablePaymentMethods
  );
  const waitingForSubscriptions = loggedIn && isSubscriptionsLoading;
  const isLoading = isCatalogLoading || waitingForSubscriptions;

  const [form, setFormParam] = useQueryParam("form", StringParam);

  useEffect(() => {
    function initializeAddresses() {
      reusePartnerAddresses(compact(currentUser.profile_addresses)).forEach(
        (address) => saveAddress(address)
      );
    }

    function initializePaymentMethods() {
      setAvailablePaymentMethods(
        uniq(subscriptions.data.map(({ iban }) => iban)).map((iban) => ({
          id: uniqueId(),
          type: "bank-account",
          iban,
        }))
      );
    }

    if (!loggedIn || isSubscriptionsLoading) {
      return;
    }

    initializeAddresses();
    initializePaymentMethods();
  }, [loggedIn, isSubscriptionsLoading, saveAddress, subscriptions]);

  useEffect(() => {
    const currentStep = (pathname.split("/") || [])[2] || "";
    setFormParam(buildAnalyticsParamFromState(state, currentStep));
  }, [state]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <TopBarLayout>
      <div
        className={clsx(classes.wrapper, {
          [classes.hasSidebar]: shouldShowPaymentSummary,
        })}
      >
        {shouldShowStepper && (
          <Container>
            <Box mt={8} mx={[0, 7]}>
              <Tiles columns={1} spacing={0}>
                <Text uppercase size="sm" letterSpacing="0.1rem">
                  {t("funnel.header.title")}
                </Text>
                <Box my={6}>
                  <Stepper
                    currentStep={pathname}
                    steps={steps.map((step) => ({
                      key: step,
                      label: t("funnel.header.steps." + step.split("/")[2]),
                    }))}
                  />
                </Box>
              </Tiles>
            </Box>
          </Container>
        )}
        <Switch>
          <Route path={`${path}/data`}>
            <Data tariffs={tariffs} />
          </Route>
          <Route path={`${path}/payment`}>
            <Payment />
          </Route>
          <Route path={`${path}/confirm`}>
            <Confirm tariffs={tariffs} />
          </Route>
          <Route path={`${path}/thanks`}>
            <Thanks />
          </Route>
        </Switch>
        {shouldShowPaymentSummary && <PaymentSummary tariffs={tariffs} />}
        <SessionListener isLoggedIn={loggedIn} />
      </div>
    </TopBarLayout>
  );
};
