import React, { useEffect, useState } from "react";
import { Text } from "components/Text";
import { Link } from "components/Link";
import { Tiles } from "components/layouts/Tiles";
import { Box } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { useStore } from "hooks/useStore";
import { Card } from "components/Card";
import { Spinner } from "components/Spinner";
import { ForwardIcon } from "components/icons/ForwardIcon";
import { BackArrow } from "components/icons/BackArrow";
import { capitalize } from "lib/helpers/string";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useQueryParam, StringParam } from "use-query-params";
import { buildAnalyticsParamFromState } from "./shared/buildAnalyticsParamFromState";
import { CheckIcon } from "components/icons/CheckIcon";
import { useHistory } from "react-router-dom";
import { Modal } from "components/Modal";
import DocumentButton from "./DocumentButton";
import ModalContent from "./ModalContent";
import { patchRequiredDocs } from "lib/api/crmLeadDocumentation";
import { theme } from "../../theme";

const secondaryColor = theme.palette.secondary.main;
const primaryColor = theme.palette.primary.main;

export const Thanks = () => {
  const history = useHistory();
  const store = useStore();
  const { t } = useTranslation();
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);
  const [error, setError] = useState(null);

  const files_template = [
    {
      src: null,
      label: t("funnel.signup.thanks.modal_docubooth_info.label_doc_1"),
      miniature: null,
    },
    {
      src: null,
      label: t("funnel.signup.thanks.modal_docubooth_info.label_doc_2"),
      miniature: null,
    },
  ];

  const [requiredDocs, setRequiredDocs] = useState(() =>
    store.crmLead.map((requiredDoc) => {
      return { ...requiredDoc, submitted: false };
    })
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [typeOfFile, setTypeOfFile] = useState(null);
  const [files] = useState(files_template);

  const requiredDocsLength = requiredDocs.length;
  const requiredDocsSent =
    requiredDocs.map((doc) => doc.submitted).filter((doc) => doc === true)
      .length === requiredDocsLength;

  const username = currentUser
    ? currentUser.first_name
    : capitalize(store.formStepDataByKey["partner/personal-data"].name);

  const onlyMembership = store.lines.length === 0;

  const [, setFormParam] = useQueryParam("form", StringParam);

  const [tracked, setTracked] = useState(false);
  useEffect(() => {
    if (window.fbq instanceof Function) {
      window.fbq("track", "Lead");
    }
    setTracked(true);
  }, []);

  useEffect(() => {
    if (loggedIn && tracked) {
      localStorage.setItem(
        "flash-message",
        t("funnel.signup.thanks.flash_message")
      );

      store.resetSignupFlow();
      history.push("/home");
    }
  }, [loggedIn, tracked]);

  useEffect(() => {
    setError(null);
  }, [modalOpen]);

  useEffect(() => {
    setFormParam(buildAnalyticsParamFromState(store, "thanks"));
  }, [store, setFormParam]);

  if (loggedIn) {
    return <Spinner />;
  }

  const handleOnSubmit = async (files) => {
    try {
      const crmLeadId = await requiredDocs.find(
        (doc) => doc.document_category === typeOfFile
      )?.crm_lead_id;

      if (!crmLeadId) {
        throw new Error(
          "No CRM lead ID found for the given document category."
        );
      }

      const validFiles = files.filter((file) => file.src !== null);

      const dataToSubmit = {
        document_category: typeOfFile,
        files: validFiles.map((file) => ({
          filename: file.name,
          content: file.src.replace(/^data:image\/[a-z]+;base64,/, ""),
        })),
      };

      await patchRequiredDocs(crmLeadId, dataToSubmit);

      setModalOpen(false);

      const newRequiredDocs = requiredDocs.map((doc) => {
        const newDoc = { ...doc };
        if (newDoc.document_category === typeOfFile) {
          newDoc.submitted = true;
        }
        return newDoc;
      });

      setRequiredDocs(newRequiredDocs);
    } catch (err) {
      console.error(
        "Error in handleOnSubmit:",
        err.response ? err.response.data : err.message
      );
      setError(t("funnel.signup.thanks.send_doc_error"));
    }
  };

  const previousOwner = store.formStepDataByKey;
  const valuesPreviousOwner = Object.values(previousOwner)[3];

  return (
    <Box className="1rst_Box_Thanks">
      <Tiles columns={2} spacing={0}>
        <video
          preload="auto"
          muted
          autoPlay
          loop
          alt={t("common.thanks")}
          style={{
            width: "100%",
            height: "100%",
            maxHeight: 400,
            objectFit: "cover",
          }}
          src="/thanks.webm"
        >
          <source src="/thanks.webm" type="video/webm" />
        </video>
        <Box
          bgcolor={secondaryColor}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={8}
          py={5}
        >
          <Tiles columns={1}>
            <CheckIcon />
            <Box style={{ width: "100%", padding: "20px 0" }}>
              <Text size="xl">
                <Trans i18nKey="funnel.signup.thanks.welcome">
                  <Text size="xl" bold>
                    {{ username }}
                  </Text>
                </Trans>
              </Text>
            </Box>
          </Tiles>
        </Box>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box
            style={{
              position: "relative",
              top: -24,
              width: "100%",
              maxWidth: 500,
            }}
          >
            {false && <Card>twitter share</Card>}
          </Box>
        </Box>
      </Tiles>
      <Box
        style={{ width: "100%", padding: "50px 150px", position: "relative" }}
      >
        <Card
          style={{
            position: "absolute",
            top: "-30%",
            right: "15%",
            left: "15%",
          }}
        >
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
                marginBottom: 20,
              }}
            >
              <ForwardIcon />
              <Text bold size="xl">
                {t("funnel.signup.thanks.now_what_title")}
              </Text>
            </Box>
            <Text size="md">
              {onlyMembership
                ? t("funnel.signup.thanks.now_what_body_only_membership")
                : t("funnel.signup.thanks.now_what_body")}
            </Text>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "45px",
            }}
          >
            {requiredDocs.map((doc) => (
              <DocumentButton
                key={doc.document_category}
                category={doc.document_category}
                submitted={doc.submitted}
                partnerPersonalData={
                  store.formStepDataByKey["partner/personal-data"]
                }
                valuesPreviousOwner={valuesPreviousOwner}
                onClick={(category) => {
                  setModalOpen(true);
                  setTypeOfFile(category);
                }}
              />
            ))}
          </Box>
          <Box style={{ marginTop: 40 }}>
            <Link
              color={primaryColor}
              to={loggedIn ? "/" : t("funnel.signup.thanks.web_home")}
              target="_blank"
            >
              <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <BackArrow />
                <Text color={primaryColor}>
                  {requiredDocsSent
                    ? t("funnel.signup.thanks.submitted_docs_back_to_home")
                    : t("funnel.signup.thanks.back_to_home")}
                </Text>
              </Box>
            </Link>
          </Box>
        </Card>
      </Box>

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth={false}
      >
        <ModalContent
          files={files}
          onSubmit={handleOnSubmit}
          error={error}
          sx={{ backGroundColor: "red" }}
          typeOfFile={typeOfFile}
          partnerPersonalData={store.formStepDataByKey["partner/personal-data"]}
          valuesPreviousOwner={valuesPreviousOwner}
        >
          <Text>{t("funnel.signup.thanks.modal." + typeOfFile)}</Text>
        </ModalContent>
      </Modal>
    </Box>
  );
};
