import { createMuiTheme } from "@material-ui/core/styles";

// TODO should be in rem actually
const toPx = (value) => `${value}px`;

export const rawTheme = {
  typography: {
    fontFamily: '"Work Sans"',
  },
  spacing: [0, 8, 10, 15, 20, 25, 30, 40, 50, 60, 75, 100, 125].map(toPx),
  palette: {
    primary: {
      dark: "#712547",
      main: "#863158",
    },
    secondary: {
      main: "#f6de59",
    },
    text: {
      main: "#3e3382",
    },
    info: {
      main: "#b1a76e",
      dark: "#8c8248",
    },
    semantic: {
      main: "#208035"
    },
    background: {
      darkest: "#808faa",
      dark: "#ced2d9",
      main: "#e8e8e8",
      light: "#f2f2f2",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "'Work Sans'",
      },
    },
  },
};

export const theme = createMuiTheme(rawTheme);
