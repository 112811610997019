export const CheckGreenIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#208035" />

    <path
      d="M12.55 21L6.84998 15.3L8.27498 13.875L12.55 18.15L21.725 8.97498L23.15 10.4L12.55 21Z"
      fill="white"
    />
  </svg>
);
