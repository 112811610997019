import { propagateLocaleParam } from "lib/helpers/urls";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const isInIframe = (() => {
  try {
    return window.self !== window.parent;
  } catch (e) {
    return true;
  }
})();

const NativeLink = ({ to, children, ...props }) => (
  <a href={to} {...props}>
    {children}
  </a>
);

/**
 * Combines MaterialUI and react-router link
 */
export const Link = ({
  target,
  to = "",
  children,
  showUnderline = true,
  openInParent,
  color,
}) => {
  const isExternalLink = Boolean(to.match(/^https?:\/\/|^mailto:/));
  const LinkComponent = isExternalLink ? NativeLink : RouterLink;
  const destination = propagateLocaleParam(to);

  return (
    <LinkComponent
      style={{
        color: color ? color : "currentColor",
        textDecoration: !showUnderline ? "none" : undefined,
      }}
      target={
        target ? target : isInIframe && openInParent ? "_parent" : "_self"
      }
      to={destination}
    >
      {children}
    </LinkComponent>
  );
};
