export const InfoEmptyIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16699 13.1667H9.83366V8.16669H8.16699V13.1667ZM9.00032 6.50002C9.23644 6.50002 9.43435 6.42016 9.59407 6.26044C9.7538 6.10072 9.83366 5.9028 9.83366 5.66669C9.83366 5.43058 9.7538 5.23266 9.59407 5.07294C9.43435 4.91321 9.23644 4.83335 9.00032 4.83335C8.76421 4.83335 8.5663 4.91321 8.40657 5.07294C8.24685 5.23266 8.16699 5.43058 8.16699 5.66669C8.16699 5.9028 8.24685 6.10072 8.40657 6.26044C8.5663 6.42016 8.76421 6.50002 9.00032 6.50002ZM9.00032 17.3334C7.84755 17.3334 6.76421 17.1146 5.75032 16.6771C4.73644 16.2396 3.85449 15.6459 3.10449 14.8959C2.35449 14.1459 1.76074 13.2639 1.32324 12.25C0.885742 11.2361 0.666992 10.1528 0.666992 9.00002C0.666992 7.84724 0.885742 6.76391 1.32324 5.75002C1.76074 4.73613 2.35449 3.85419 3.10449 3.10419C3.85449 2.35419 4.73644 1.76044 5.75032 1.32294C6.76421 0.885437 7.84755 0.666687 9.00032 0.666687C10.1531 0.666687 11.2364 0.885437 12.2503 1.32294C13.2642 1.76044 14.1462 2.35419 14.8962 3.10419C15.6462 3.85419 16.2399 4.73613 16.6774 5.75002C17.1149 6.76391 17.3337 7.84724 17.3337 9.00002C17.3337 10.1528 17.1149 11.2361 16.6774 12.25C16.2399 13.2639 15.6462 14.1459 14.8962 14.8959C14.1462 15.6459 13.2642 16.2396 12.2503 16.6771C11.2364 17.1146 10.1531 17.3334 9.00032 17.3334ZM9.00032 15.6667C10.8614 15.6667 12.4378 15.0209 13.7295 13.7292C15.0212 12.4375 15.667 10.8611 15.667 9.00002C15.667 7.13891 15.0212 5.56252 13.7295 4.27085C12.4378 2.97919 10.8614 2.33335 9.00032 2.33335C7.13921 2.33335 5.56283 2.97919 4.27116 4.27085C2.97949 5.56252 2.33366 7.13891 2.33366 9.00002C2.33366 10.8611 2.97949 12.4375 4.27116 13.7292C5.56283 15.0209 7.13921 15.6667 9.00032 15.6667Z"
      fill="#3E3382"
    />
  </svg>
);
