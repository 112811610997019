export const ForwardIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#3E3382" />
    <path
      d="M11.5 26V14L20.5 20L11.5 26ZM21.5 26V14L30.5 20L21.5 26ZM13.5 22.25L16.9 20L13.5 17.75V22.25ZM23.5 22.25L26.9 20L23.5 17.75V22.25Z"
      fill="white"
    />
  </svg>
);

// export const ForwardIcon = () => (
//     <svg
//       width="40"
//       height="40"
//       viewBox="0 0 40 40"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <circle cx="20" cy="20" r="20" fill="#3E3382" />
//       <mask
//         id="mask0_6_4449"
//         style="mask-type:alpha"
//         maskUnits="userSpaceOnUse"
//         x="9"
//         y="8"
//         width="24"
//         height="24"
//       >
//         <rect x="9" y="8" width="24" height="24" fill="#D9D9D9" />
//       </mask>
//       <g mask="url(#mask0_6_4449)">
//         <path
//           d="M11.5 26V14L20.5 20L11.5 26ZM21.5 26V14L30.5 20L21.5 26ZM13.5 22.25L16.9 20L13.5 17.75V22.25ZM23.5 22.25L26.9 20L23.5 17.75V22.25Z"
//           fill="white"
//         />
//       </g>
//     </svg>
//   );
